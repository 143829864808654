import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './Generate.css';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { DataContext } from '../DataContext';
import { languageOptions } from './LanguageOptions';
import DataTable from 'react-data-table-component';
import CryptoJS from 'crypto-js';
import { OpenAI } from 'openai';
import dataPlacesAPI from './dataPlacesAPI.js';

function GenerateMultipleSeries({ getToken }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  // ----------------------
  // State variables
  // ----------------------
  const [language, setLanguage] = useState("en");
  const [additionalLanguages, setAdditionalLanguages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [generatedIdeas, setGeneratedIdeas] = useState([]); // Main city/attraction ideas
  const [spinoffIdeas, setSpinoffIdeas] = useState([]);    // Spinoff ideas (secondary table)
  const [status, setStatus] = useState('');
  const [log, setLog] = useState([]);
  const [selectedCity, setSelectedCity] = useState('London');
  const [generateSpinoffs, setGenerateSpinoffs] = useState(false); // Toggle for spinoff generation

  // ----------------------
  // Environment / Config
  // ----------------------
  const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
  const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
  const accessToken = process.env.REACT_APP_ACCESS_TOKEN;

  // ----------------------
  // Utility
  // ----------------------
  const generateUniqueKey = (name) => {
    return CryptoJS.SHA256(name).toString(CryptoJS.enc.Hex);
  };

  // ----------------------
  // GPT: Get spinoff ideas (without immediately creating them)
  // ----------------------
  const fetchSpinOffIdeas = async (attraction, city_json) => {
    /**
     * Original `createSpinOffsAttraction` function made the GPT call 
     * and then immediately created spin-off series. 
     * Here, we'll do a simpler version that just *gets the spinoff ideas* 
     * and returns them. We'll store them in state until the user decides to generate them.
     */
    const client = new OpenAI({ apiKey: process.env.REACT_APP_OPENAI_API_KEY, dangerouslyAllowBrowser:true });
    const prompt = [
      {
        role: "system",
        content:
          "You are a creative assistant that generates ideas for *audio guide spin-off series*. " +
          "They focus on interesting/fun/historical/horror aspects indirectly related to the monument " +
          "and not the typical tourist info. Return JSON: { list: [ {title: string, desc: string} ] } " +
          "with 1 or more ideas. Each description ~70-80 words."
      },
      {
        role: "user",
        content: `If I create an audioguide about ${attraction.name}, what could be 1 spin-off audio series I could create?`
      }
    ];

    try {
      const response = await client.chat.completions.create({
        model: "gpt-3.5-turbo",
        messages: prompt,
        temperature: 0.9
      });

      if (response.choices && response.choices.length > 0) {
        const content = response.choices[0].message.content;
        setLog(prev => [...prev, `Fetched spinoff GPT ideas for ${attraction.name}: ${content}`]);

        let spinoffs = [];
        try {
          const parsed = JSON.parse(content);
          spinoffs = parsed.list || [];
        } catch (err) {
          setLog(prev => [...prev, `Error parsing spinoff GPT JSON: ${err}`]);
        }

        // Convert to your local shape for the spinoff table
        return spinoffs.map((item) => ({
          attractionName: attraction.name,
          attractionData: attraction,   // So we know how to generate later
          title: item.title,
          desc: item.desc,
          selected: true
        }));
      } else {
        setLog(prev => [...prev, `No spinoff GPT data returned for ${attraction.name}`]);
        return [];
      }
    } catch (error) {
      setLog(prev => [...prev, `Error in spinoff GPT call: ${error.message}`]);
      return [];
    }
  };

  // ----------------------
  // Actually create the spin-off series in the backend if user selects them
  // ----------------------
  const generateSpinOffSeriesAttraction = async (spinoffItem, city_json) => {
    // spinoffItem has { attractionData, title, desc, selected }
    const attraction = spinoffItem.attractionData;
    if (!attraction) {
      setLog(prev => [...prev, `No attraction data found for spinoff: ${spinoffItem.title}`]);
      return;
    }

    setLog(prevLog => [...prevLog, `Generating spin off series for attraction: ${attraction.name} => ${spinoffItem.title}`]);
    const url = `${BASE_API_URL}series/generate`;

    const payload = {
      title: spinoffItem.title,
      name: attraction.name,
      desc: spinoffItem.desc,
      language: "en",
      additional_languages: ["fr"],
      accessToken,
      style: "historical",
      voice: getRandomVoice(false),
      system: "epopea",
      minutes: 5,
      tags: ["historical", "spinoff", attraction.name, spinoffItem.title],
      type: "monument",
      element_key: generateUniqueKey(attraction.name + spinoffItem.title),
      img: attraction.photo_url || "",
      individual_images: false,
      data: {
        name: attraction.name,
        formatted_address: attraction.formatted_address,
        place_id: attraction.place_id,
        reference: attraction.reference,
        types: attraction.types,
        photo_url: attraction.photo_url,
        geo_location: attraction.geo_location,
        extra: attraction.data
      },
      postpone_generation: true,
      geo_location: attraction.geo_location,
      country: city_json.country.country,
      city: city_json.name,
      country_id: city_json.country.country_id,
      city_id: city_json.city_id,
      country_code: city_json.country.iso
    };

    setLog(prevLog => [...prevLog, `API call for spinoff series generation: ${url}`]);
    setLog(prevLog => [...prevLog, JSON.stringify(payload, null, 2)]);

    try {
      const response = await axios.post(url, payload, {
        headers: { Authorization: `Bearer ${accessToken}` }
      });
      if (response.status === 200) {
        setLog(prevLog => [...prevLog, `Spinoff series generated: ${spinoffItem.title}`]);
        // Payment creation if needed:
        // await createPaymentForSeries(response.data.object[0]._id);
      } else {
        setLog(prevLog => [...prevLog, `Failed to generate spinoff series for ${attraction.name}`]);
      }
    } catch (error) {
      setLog(prevLog => [...prevLog, `Error: ${error.message}`]);
    }
  };

  // ----------------------
  // Create city series
  // ----------------------
  const createSerie = async (city_json, first_attraction) => {
    const url = `${BASE_API_URL}series/create`;
    const payload = {
      title: `City of ${city_json.name}, ${city_json.country.country}`,
      name: `City of ${city_json.name}`,
      desc: `Step-by-step audio guide for the city of ${city_json.name}, providing detailed insights and explanations.`,
      style: "audioguides",
      language: "en",
      additional_languages: ["fr"],
      accessToken,
      img: first_attraction.photo_url || "",
      system: "epopea",
      geo_location: city_json.geo_location,
      country: city_json.country.country,
      city: city_json.name,
      country_id: city_json.country.country_id,
      city_id: city_json.city_id,
      country_code: city_json.country.iso,
      city_tour: generateUniqueKey(city_json.name),
      type: "city_tour",
      element_key: generateUniqueKey(city_json.name),
      tags: ["city tour", "historical", "top attractions", city_json.name],
    };

    setLog(prevLog => [...prevLog, `API call for creating city series => ${url}`]);
    setLog(prevLog => [...prevLog, JSON.stringify(payload, null, 2)]);

    try {
      const response = await axios.post(url, payload, {
        headers: { Authorization: `Bearer ${accessToken}` }
      });
      if (response.data.created) {
        setLog(prevLog => [...prevLog, `Created new city series: ${response.data.object[0]._id}`]);
        // If you do payments for each series, do it here:
        // await createPaymentForSeries(response.data.object[0]._id);
        return response.data.object[0]._id;
      } else {
        setLog(prevLog => [...prevLog, "Error creating series"]);
        return null;
      }
    } catch (error) {
      setLog(prevLog => [...prevLog, `Error in createSerie: ${error.message}`]);
      return null;
    }
  };

  // ----------------------
  // Generate an episode for an attraction
  // ----------------------
  const generateEpisode = async (serie_id, attraction, city_json) => {
    const monument_key = generateUniqueKey(attraction.name);
    const url = `${BASE_API_URL}episode/generate`;
    const payload = {
      title: attraction.name,
      name: attraction.name,
      desc: `Step-by-step audio guide for ${attraction.name}, providing detailed insights and explanations.`,
      additional_languages: ["fr"],
      language: "en",
      voice: getRandomVoice(false),
      img: attraction.photo_url || "",
      series: serie_id,
      minutes: 5,
      style: "audioguides",
      system: "epopea",
      type: "monument",
      element_key: monument_key,
      tags: ["monument", "historical", "landmark", attraction.name],
      data: {
        name: attraction.name,
        formatted_address: attraction.formatted_address,
        place_id: attraction.place_id,
        reference: attraction.reference,
        types: attraction.types,
        photo_url: attraction.photo_url,
        geo_location: attraction.geo_location,
        extra: attraction.data
      },
      postpone_generation: false,
      geo_location: attraction.geo_location,
      country: city_json.country.country,
      city: city_json.name,
      country_id: city_json.country.country_id,
      city_id: city_json.city_id,
      country_code: city_json.country.iso
    };

    setLog(prevLog => [...prevLog, `API call for generating episode => ${url}`]);
    setLog(prevLog => [...prevLog, JSON.stringify(payload, null, 2)]);

    try {
      const response = await axios.post(url, payload, {
        headers: { Authorization: `Bearer ${accessToken}` }
      });
      if (response.status === 200) {
        setLog(prevLog => [...prevLog, `Episode generated for ${attraction.name}`]);
      } else {
        setLog(prevLog => [...prevLog, `Failed to generate episode for ${attraction.name}`]);
      }
    } catch (error) {
      setLog(prevLog => [...prevLog, `Error: ${error.message}`]);
    }
  };

  // ----------------------
  // Generate city guide (main series + episodes)
  // ----------------------
  const generateTheMainCity = async (city_json, attractions) => {
    if (!attractions || attractions.length === 0) {
      setLog(prevLog => [...prevLog, `No attractions found for ${city_json.name}.`]);
      return;
    }
    const serie_id = await createSerie(city_json, attractions[0]);
    if (serie_id) {
      for (const attraction of attractions) {
        await generateEpisode(serie_id, attraction, city_json);
      }
    }
  };

  // ----------------------
  // Fetch & Sort attractions from local mock or Google Places
  // ----------------------
  const getTouristAttractionsWithDetails = async (city_name = 'Dublin') => {
    const base_url = "https://maps.googleapis.com/maps/api/place/textsearch/json?";
    const query = `historical tourist attractions in ${city_name}`;
    const params = { query, key: API_KEY };

    try {
      // If you want the real API call:
      // const response = await axios.get(base_url, { params });
      // const results = response.data;
      // For demonstration, we just load local data:
      const results = dataPlacesAPI;

      if (results.status === 'OK') {
        const attractions = results.results;
        const sorted_attractions = attractions.sort((a, b) => b.user_ratings_total - a.user_ratings_total);

        const output = sorted_attractions.map(attraction => {
          const data = {
            geometry: {
              location: {
                lat: attraction.geometry.location.lat,
                lng: attraction.geometry.location.lng
              },
              viewport: {
                northeast: {
                  lat: attraction.geometry.viewport.northeast.lat,
                  lng: attraction.geometry.viewport.northeast.lng
                },
                southwest: {
                  lat: attraction.geometry.viewport.southwest.lat,
                  lng: attraction.geometry.viewport.southwest.lng
                }
              }
            },
            photos: [
              {
                height: attraction.photos?.[0]?.height || 'N/A',
                width: attraction.photos?.[0]?.width || 'N/A'
              }
            ],
            rating: attraction.rating || 'No rating',
            user_ratings_total: attraction.user_ratings_total || 0
          };

          return {
            data,
            formatted_address: attraction.formatted_address || 'N/A',
            name: attraction.name || 'N/A',
            place_id: attraction.place_id || 'N/A',
            reference: attraction.reference || 'N/A',
            types: attraction.types || [],
            photo_url: attraction.photos?.[0]?.photo_reference
              ? `https://maps.googleapis.com/maps/api/place/photo?maxwidth=400&photoreference=${attraction.photos[0].photo_reference}&key=${API_KEY}`
              : null,
            geo_location: {
              type: "Point",
              coordinates: [
                attraction.geometry.location.lng,
                attraction.geometry.location.lat
              ]
            }
          };
        });

        return output;
      } else {
        setLog(prevLog => [...prevLog, `Error fetching attractions: ${results.status}`]);
        return [];
      }
    } catch (error) {
      setLog(prevLog => [...prevLog, `Error: ${error.message}`]);
      return [];
    }
  };

  // ----------------------
  // Random voice helper
  // ----------------------
  const getRandomVoice = (premium) => {
    setLog(prevLog => [
      ...prevLog,
      premium ? "Getting a premium voice" : "Getting a free voice"
    ]);
    const voicesList = [
      {
        "_id": "660594db5f3cc96b0218af26",
        "name": "Bethany",
        "category": "premade",
        "labels": {
          "accent": "american",
          "age": "middle aged",
          "gender": "female",
          "usecase": "scientific"
        },
        "id": "alloy",
        "premium": false,
      },
      {
        "_id": "660594db5f3cc96b0218af28",
        "name": "Thomas",
        "category": "premade",
        "labels": {
          "accent": "english",
          "age": "middle aged",
          "gender": "male",
          "usecase": "podcast"
        },
        "id": "fable",
        "premium": false,
      },
      {
        "_id": "660594db5f3cc96b0218af29",
        "name": "Leo",
        "category": "premade",
        "labels": {
          "accent": "english",
          "age": "middle aged",
          "gender": "male",
          "usecase": "audioguide"
        },
        "id": "echo",
        "premium": false,
      },
      {
        "_id": "660594db5f3cc96b0218af2a",
        "name": "Michael",
        "category": "premade",
        "labels": {
          "accent": "american",
          "age": "mature",
          "gender": "male",
          "usecase": "audioguide"
        },
        "id": "onyx",
        "premium": false,
      },
      {
        "_id": "660594db5f3cc96b0218af2b",
        "name": "Carmen",
        "category": "premade",
        "labels": {
          "accent": "american",
          "age": "middle aged",
          "gender": "female",
          "usecase": "audiobook"
        },
        "id": "nova",
        "premium": false,
      },
      {
        "_id": "660594db5f3cc96b0218af2c",
        "name": "Jessy",
        "category": "premade",
        "labels": {
          "accent": "american",
          "age": "young",
          "gender": "female",
          "usecase": "news"
        },
        "id": "shimmer",
        "premium": false,
      }
    ];

    return voicesList[Math.floor(Math.random() * voicesList.length)];
  };

  // ----------------------
  // STEP 1: Click "Generate Ideas"
  // Fetch attractions => create "ideas" list => optionally fetch spinoffs
  // ----------------------
  const handleStart = async () => {
    setLog([]);
    setStatus('Fetching attractions...');
    setIsLoading(true);

    try {
      const json_data = await getTouristAttractionsWithDetails(selectedCity);

      // Hard-coded city info for example:
      const city_json = {
        _id: { $oid: "66e316b72e6c21eebdb4b37d" },
        city_id: 2643743,
        name: selectedCity,
        ascii_name: "London",
        latitude: 51.50853,
        longitude: -0.12574,
        country_code: "GB",
        population: 8961989,
        timezone: "Europe/London",
        geo_location: {
          type: "Point",
          coordinates: [-0.12574, 51.50853]
        },
        country: {
          country_id: 2635167,
          iso: "GB",
          country: "United Kingdom",
          currency_code: "GBP",
          currency_symbol: "£"
        },
        price_in_pence: 1250
      };

      // Basic idea objects for main attractions
      const ideas = json_data.map((attraction) => ({
        title: attraction.name,
        desc: `Step-by-step audio guide for ${attraction.name}, providing detailed insights and explanations.`,
        selected: true,
        attractionData: attraction // store for generating episodes
      }));

      setGeneratedIdeas(ideas);
      setStatus('Attractions fetched');

      // If user has toggled spinoffs, let's fetch them for each attraction
      // (or adapt if you only want spinoffs for the first attraction/city, etc.)
      if (generateSpinoffs) {
        let allSpinoffs = [];
        for (const attraction of json_data) {
          const spinoffsForThis = await fetchSpinOffIdeas(attraction, city_json);
          allSpinoffs.push(...spinoffsForThis);
        }
        setSpinoffIdeas(allSpinoffs);
      }
    } catch (error) {
      setLog(prevLog => [...prevLog, `Error in handleStart: ${error.message}`]);
    } finally {
      setIsLoading(false);
    }
  };

  // ----------------------
  // STEP 2: "Generate All" - actually create series in the backend
  // for both main ideas and spinoffs that are selected
  // ----------------------
  const generateAllSeries = async () => {
    setStatus('Generating series...');
    setIsLoading(true);

    // We might need city info again; in a real scenario, store it in state or re-fetch.
    // Hard-coded for example:
    const city_json = {
      _id: { $oid: "66e316b72e6c21eebdb4b37d" },
      city_id: 2643743,
      name: selectedCity,
      ascii_name: "London",
      latitude: 51.50853,
      longitude: -0.12574,
      country_code: "GB",
      population: 8961989,
      timezone: "Europe/London",
      geo_location: {
        type: "Point",
        coordinates: [-0.12574, 51.50853]
      },
      country: {
        country_id: 2635167,
        iso: "GB",
        country: "United Kingdom",
        currency_code: "GBP",
        currency_symbol: "£"
      },
      price_in_pence: 1250
    };

    try {
      // 1) Generate the main "city" series with episodes for attractions
      //    that are selected in the main table
      const selectedAttractions = generatedIdeas.filter((idea) => idea.selected);

      if (selectedAttractions.length > 0) {
        const attractionsData = selectedAttractions.map((i) => i.attractionData);
        await generateTheMainCity(city_json, attractionsData);
      } else {
        setLog(prev => [...prev, "No main attractions selected. Skipping city generation."]);
      }

      // 2) Generate spinoffs for all selected spinoffs
      const selectedSpinoffs = spinoffIdeas.filter((idea) => idea.selected);
      for (const spinoffItem of selectedSpinoffs) {
        await generateSpinOffSeriesAttraction(spinoffItem, city_json);
      }

      setStatus('All series generation completed');
    } catch (error) {
      setLog(prev => [...prev, `Error generating all series: ${error.message}`]);
      setStatus('Error during generation');
    } finally {
      setIsLoading(false);
    }
  };

  // ----------------------
  // Toggle (checkbox) for main table
  // ----------------------
  const toggleSelectionMain = (index) => {
    const updated = [...generatedIdeas];
    updated[index].selected = !updated[index].selected;
    setGeneratedIdeas(updated);
  };

  // ----------------------
  // Toggle (checkbox) for spinoff table
  // ----------------------
  const toggleSelectionSpinoff = (index) => {
    const updated = [...spinoffIdeas];
    updated[index].selected = !updated[index].selected;
    setSpinoffIdeas(updated);
  };

  // ----------------------
  // Table columns
  // ----------------------
  const columnsMain = [
    {
      name: t('title'),
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: t('description'),
      selector: (row) => row.desc,
      sortable: true,
    },
    {
      name: t('actions'),
      cell: (row, index) => (
        <input
          type="checkbox"
          checked={row.selected}
          onChange={() => toggleSelectionMain(index)}
        />
      ),
    },
  ];

  const columnsSpinoff = [
    {
      name: "Spinoff Title",
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: "Spinoff Description",
      selector: (row) => row.desc,
      sortable: true,
    },
    {
      name: "Attraction",
      selector: (row) => row.attractionName,
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row, index) => (
        <input
          type="checkbox"
          checked={row.selected}
          onChange={() => toggleSelectionSpinoff(index)}
        />
      ),
    },
  ];

  // ----------------------
  // Render
  // ----------------------
  if (isLoading) {
    return (
      <div className="spinner-container">
        <div className="spinner"></div>
        <div className="loading-text">{t('loading')}</div>
      </div>
    );
  }

  return (
    <div className="content-package" id="generator">
      <h1 id="baseSelectorTitle">{t('createMultipleSeriesTitle')}</h1>

      {/* City Selection */}
      <p className="form-label">{t('selectCity')}</p>
      <select
        value={selectedCity}
        onChange={(e) => setSelectedCity(e.target.value)}
      >
        <option value="London">London</option>
        <option value="Dublin">Dublin</option>
        {/* Add more if needed */}
      </select>

      {/* Main Language */}
      <p className="form-label">{t('selectLanguage')}</p>
      <select value={language} onChange={(e) => setLanguage(e.target.value)}>
        {languageOptions.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>

      {/* Additional Languages */}
      <p className="form-label">{t('selectAdditionalLanguages')}</p>
      <Select
        isMulti
        options={languageOptions.filter(option => option.value !== language)}
        onChange={setAdditionalLanguages}
        className="basic-multi-select"
        classNamePrefix="select"
      />

      {/* Toggle for spinoffs */}
      <div style={{ margin: "1rem 0" }}>
        <label>
          <input
            type="checkbox"
            checked={generateSpinoffs}
            onChange={(e) => setGenerateSpinoffs(e.target.checked)}
          />
          {" Generate spinoffs?"}
        </label>
      </div>

      {/* Button: Start (fetch main ideas, optionally spinoffs) */}
      <button onClick={handleStart}>{t('generateIdeasButton')}</button>

      {/* Table of main ideas */}
      {generatedIdeas.length > 0 && (
        <>
          <h2>Main Ideas</h2>
          <DataTable
            columns={columnsMain}
            data={generatedIdeas}
            pagination
            highlightOnHover
            responsive
            noHeader
          />
        </>
      )}

      {/* Table of spinoffs (only if generated) */}
      {spinoffIdeas.length > 0 && (
        <>
          <h2>Spinoff Ideas</h2>
          <DataTable
            columns={columnsSpinoff}
            data={spinoffIdeas}
            pagination
            highlightOnHover
            responsive
            noHeader
          />
        </>
      )}

      {/* Button: Generate all series */}
      {(generatedIdeas.length > 0 || spinoffIdeas.length > 0) && (
        <button onClick={generateAllSeries}>{t('generateAllSeriesButton')}</button>
      )}

      {/* Status & Log */}
      <div>
        <h2>Status: {status}</h2>
        <div>
          <h3>Log:</h3>
          <pre>{log.join('\n')}</pre>
        </div>
      </div>
    </div>
  );
}

export default GenerateMultipleSeries;
