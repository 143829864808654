const places = {
    "html_attributions" : [],
    "next_page_token" : "AWYs27xr7g5KzlE-1D5--SP-tNBTeWlHQLwyjilfvEiFn2oke0KQezkT0VQtQNFXQv_2ipJvAMhROwGytO0R0aJLsqGbdQCMJfrFUtVjG0gVa3fwdjHUAJWRyjH3A7y6jsJL3mSK46vDnjIiI9OB6TBFzdYnwFpxWIJqYCPaCNOmR0v-qrBJIvVdifIL6vRw748XTVtC-6Rcczj8_pctoKB4zzvgLMoMaE9yTRcLXFnIXVNRxRkUKqNG37QmxABcd-Rh63ZrCFl5SMO2MMywsnlC7Qp1ZJYIgCvbuTiw_SNg89DKYm_dIwm925mXjOTuYNiH88bPG9E-2CUIYSWNHQ5apXs43CM3EoLAvDmaUjPfa2Suun7s5U-5Jv8vO4S2SsfjOyFV0niGZzQQeT1u13ypeYXa4xUGoIUEtuo4533v0KJ1oQyv98c",
    "results" : 
    [
       {
          "business_status" : "OPERATIONAL",
          "formatted_address" : "12 Walbrook, City of London, London EC4N 8AA, Royaume-Uni",
          "geometry" : 
          {
             "location" : 
             {
                "lat" : 51.5123685,
                "lng" : -0.0904739
             },
             "viewport" : 
             {
                "northeast" : 
                {
                   "lat" : 51.51394072989271,
                   "lng" : -0.08889972010727777
                },
                "southwest" : 
                {
                   "lat" : 51.51124107010727,
                   "lng" : -0.09159937989272221
                }
             }
          },
          "icon" : "https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/museum-71.png",
          "icon_background_color" : "#13B5C7",
          "icon_mask_base_uri" : "https://maps.gstatic.com/mapfiles/place_api/icons/v2/museum_pinlet",
          "name" : "mithraeum de Londres",
          "opening_hours" : 
          {
             "open_now" : true
          },
          "photos" : 
          [
             {
                "height" : 1816,
                "html_attributions" : 
                [
                   "\u003ca href=\"https://maps.google.com/maps/contrib/100770108664080084228\"\u003eSteven Bunner\u003c/a\u003e"
                ],
                "photo_reference" : "AWYs27yP1hDQTVPPgLIx2ItqgCC6VzzZIPDjnV_H4qlIFBcGwuuJ7ZEHU-s09VgS930nWLraO64WbDHYHU2FxHx1Cs6uwQs7RAcrHyNKSb79LoN0Bje4bpHIOkipVnbVBesaeSb_wge4xjln-ENSciqNMKNqC5Rd9ErUHc3AabElQAJl2T_a",
                "width" : 4032
             }
          ],
          "place_id" : "ChIJB9JfU60cdkgR4Sf2_6-NZ4w",
          "plus_code" : 
          {
             "compound_code" : "GW65+WR Londres",
             "global_code" : "9C3XGW65+WR"
          },
          "rating" : 4.5,
          "reference" : "ChIJB9JfU60cdkgR4Sf2_6-NZ4w",
          "types" : 
          [
             "tourist_attraction",
             "museum",
             "point_of_interest",
             "establishment"
          ],
          "user_ratings_total" : 2904
       },
       {
          "business_status" : "OPERATIONAL",
          "formatted_address" : "London EC3N 4AB, Royaume-Uni",
          "geometry" : 
          {
             "location" : 
             {
                "lat" : 51.50811239999999,
                "lng" : -0.0759493
             },
             "viewport" : 
             {
                "northeast" : 
                {
                   "lat" : 51.51027975,
                   "lng" : -0.07485549999999999
                },
                "southwest" : 
                {
                   "lat" : 51.50698794999999,
                   "lng" : -0.0792307
                }
             }
          },
          "icon" : "https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/generic_business-71.png",
          "icon_background_color" : "#13B5C7",
          "icon_mask_base_uri" : "https://maps.gstatic.com/mapfiles/place_api/icons/v2/generic_pinlet",
          "name" : "Tour de Londres",
          "opening_hours" : 
          {
             "open_now" : false
          },
          "photos" : 
          [
             {
                "height" : 3024,
                "html_attributions" : 
                [
                   "\u003ca href=\"https://maps.google.com/maps/contrib/101671647642250782192\"\u003eTony Gash\u003c/a\u003e"
                ],
                "photo_reference" : "AWYs27wrDGeUiFKoQ4ClNI3eu8_GOvtmJpbpo7P0KU2FswnGF5cBlehv3na2Y0LmN3hQO27UZlm17OgzWsIIuAwe4RWP9wJFZ7Zuiot3ryxQTRreKUQOJa_SOpQHkLMCB3XB4rMxGDfKasHrLtJmegnTQ3Xo_oaHbiorY6KCCJosGBqrxsvf",
                "width" : 4032
             }
          ],
          "place_id" : "ChIJ3TgfM0kDdkgRZ2TV4d1Jv6g",
          "plus_code" : 
          {
             "compound_code" : "GW5F+6J Londres",
             "global_code" : "9C3XGW5F+6J"
          },
          "rating" : 4.7,
          "reference" : "ChIJ3TgfM0kDdkgRZ2TV4d1Jv6g",
          "types" : 
          [
             "tourist_attraction",
             "landmark",
             "point_of_interest",
             "establishment"
          ],
          "user_ratings_total" : 109410
       },
       {
          "business_status" : "OPERATIONAL",
          "formatted_address" : "Great Russell St, London WC1B 3DG, Royaume-Uni",
          "geometry" : 
          {
             "location" : 
             {
                "lat" : 51.5194133,
                "lng" : -0.1269566
             },
             "viewport" : 
             {
                "northeast" : 
                {
                   "lat" : 51.52145824999999,
                   "lng" : -0.12476445
                },
                "southwest" : 
                {
                   "lat" : 51.51730845000001,
                   "lng" : -0.12914665
                }
             }
          },
          "icon" : "https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/museum-71.png",
          "icon_background_color" : "#13B5C7",
          "icon_mask_base_uri" : "https://maps.gstatic.com/mapfiles/place_api/icons/v2/museum_pinlet",
          "name" : "British Museum",
          "opening_hours" : 
          {
             "open_now" : true
          },
          "photos" : 
          [
             {
                "height" : 3024,
                "html_attributions" : 
                [
                   "\u003ca href=\"https://maps.google.com/maps/contrib/100875867559858145999\"\u003eKoon Sen Lo\u003c/a\u003e"
                ],
                "photo_reference" : "AWYs27yRnsbEuPc6dhCE-tEflP2fzZYjWKUxSg2v_rOUD2Mv3LhxXOFo-0q7ZJcaV0VRKY6K3fEKgxCd2lElG0YlKk6BscPk7hUYLYcJv7J7geZ45cJvDpq-_X2gkbNCoAId_ZmX6hBvYeayFOMglXGC2C92sS3JVF19Z9abMgZNqwabOI8F",
                "width" : 4032
             }
          ],
          "place_id" : "ChIJB9OTMDIbdkgRp0JWbQGZsS8",
          "plus_code" : 
          {
             "compound_code" : "GV9F+Q6 Londres",
             "global_code" : "9C3XGV9F+Q6"
          },
          "rating" : 4.7,
          "reference" : "ChIJB9OTMDIbdkgRp0JWbQGZsS8",
          "types" : 
          [
             "museum",
             "tourist_attraction",
             "point_of_interest",
             "establishment"
          ],
          "user_ratings_total" : 157701
       },
       {
          "business_status" : "OPERATIONAL",
          "formatted_address" : "London SW1A 1AA, Royaume-Uni",
          "geometry" : 
          {
             "location" : 
             {
                "lat" : 51.50188139999999,
                "lng" : -0.1406225
             },
             "viewport" : 
             {
                "northeast" : 
                {
                   "lat" : 51.50292757989271,
                   "lng" : -0.1394421701072778
                },
                "southwest" : 
                {
                   "lat" : 51.50022792010727,
                   "lng" : -0.1421418298927222
                }
             }
          },
          "icon" : "https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/generic_business-71.png",
          "icon_background_color" : "#13B5C7",
          "icon_mask_base_uri" : "https://maps.gstatic.com/mapfiles/place_api/icons/v2/generic_pinlet",
          "name" : "Victoria Memorial",
          "opening_hours" : 
          {
             "open_now" : true
          },
          "photos" : 
          [
             {
                "height" : 2022,
                "html_attributions" : 
                [
                   "\u003ca href=\"https://maps.google.com/maps/contrib/110644198353999712106\"\u003eK.-H. Vollrath\u003c/a\u003e"
                ],
                "photo_reference" : "AWYs27xEdMHq5jl0clUrwBbLV329-v9mZzIFgoEmwIffpBSXflwE7ZBb0Cf0lI-VAUkP_JksN6Ud5uV4kWN63HLNKRxuE3BhfZc4PZ1nLW7GDsKlhT8xVKo1sAVOXZpuort2SWzD3gdBhjmWpb2Q79Mx4P4ptQA8qNoS3W1suALF7bmhyZA",
                "width" : 3033
             }
          ],
          "place_id" : "ChIJqXoOftgEdkgRKsU7sw2WX3E",
          "plus_code" : 
          {
             "compound_code" : "GV25+QQ Londres",
             "global_code" : "9C3XGV25+QQ"
          },
          "rating" : 4.7,
          "reference" : "ChIJqXoOftgEdkgRKsU7sw2WX3E",
          "types" : 
          [
             "tourist_attraction",
             "point_of_interest",
             "establishment"
          ],
          "user_ratings_total" : 3577
       },
       {
          "business_status" : "OPERATIONAL",
          "formatted_address" : "Cromwell Rd, South Kensington, London SW7 5BD, Royaume-Uni",
          "geometry" : 
          {
             "location" : 
             {
                "lat" : 51.49671499999999,
                "lng" : -0.1763672
             },
             "viewport" : 
             {
                "northeast" : 
                {
                   "lat" : 51.49753612989272,
                   "lng" : -0.1716754
                },
                "southwest" : 
                {
                   "lat" : 51.49483647010727,
                   "lng" : -0.1802017999999999
                }
             }
          },
          "icon" : "https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/museum-71.png",
          "icon_background_color" : "#13B5C7",
          "icon_mask_base_uri" : "https://maps.gstatic.com/mapfiles/place_api/icons/v2/museum_pinlet",
          "name" : "Musée d'histoire naturelle de Londres",
          "opening_hours" : 
          {
             "open_now" : true
          },
          "photos" : 
          [
             {
                "height" : 3265,
                "html_attributions" : 
                [
                   "\u003ca href=\"https://maps.google.com/maps/contrib/115774484452151058757\"\u003eDebora Lopez\u003c/a\u003e"
                ],
                "photo_reference" : "AWYs27yDOjjWCUx8oaxQu528BfU7TgkzWrxl9B3u0PCNfphiQ-eM-46PI-Fbl9z_bptWUVA18r2bfDefJ1fkSYLNqbvvkpHwF2b_B85xzSScCrXT3kqk1pQZwITBukB7qnCsKxnrWBtr69D85jv00yRRliLR6e6u5KK0lr63v_tOLipWAyP9",
                "width" : 4898
             }
          ],
          "place_id" : "ChIJPy8Y5kIFdkgRxGSXw4Xjt3s",
          "plus_code" : 
          {
             "compound_code" : "FRWF+MF Londres",
             "global_code" : "9C3XFRWF+MF"
          },
          "rating" : 4.7,
          "reference" : "ChIJPy8Y5kIFdkgRxGSXw4Xjt3s",
          "types" : 
          [
             "museum",
             "tourist_attraction",
             "library",
             "point_of_interest",
             "establishment"
          ],
          "user_ratings_total" : 28435
       },
       {
          "business_status" : "OPERATIONAL",
          "formatted_address" : "Fish St Hill, City of London, London EC3R 8AH, Royaume-Uni",
          "geometry" : 
          {
             "location" : 
             {
                "lat" : 51.510143,
                "lng" : -0.08594199999999999
             },
             "viewport" : 
             {
                "northeast" : 
                {
                   "lat" : 51.51144937989272,
                   "lng" : -0.08442332010727777
                },
                "southwest" : 
                {
                   "lat" : 51.50874972010728,
                   "lng" : -0.0871229798927222
                }
             }
          },
          "icon" : "https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/generic_business-71.png",
          "icon_background_color" : "#13B5C7",
          "icon_mask_base_uri" : "https://maps.gstatic.com/mapfiles/place_api/icons/v2/generic_pinlet",
          "name" : "Monument au Grand incendie de Londres",
          "opening_hours" : 
          {
             "open_now" : true
          },
          "photos" : 
          [
             {
                "height" : 2153,
                "html_attributions" : 
                [
                   "\u003ca href=\"https://maps.google.com/maps/contrib/116452305114149665720\"\u003eHajnalka Burai\u003c/a\u003e"
                ],
                "photo_reference" : "AWYs27w87AcoHhtahKZPPj_K72vY3PEPnEcxKDoESGlLTpMHYtvk0tPOSNWVBpgGSZE3bLIOBpi_NokwSUsazJXo3nOyEAvB1g0fZaxPkwlUM-nKhxa_vGC_-MMxcWQHupEV2AC_g4m95hLSSgtt3c-2oaWZnHmZF0A7j7H7IGpY-QIHo0FA",
                "width" : 2466
             }
          ],
          "place_id" : "ChIJDz0Q3FMDdkgRw0x0c68WGtk",
          "plus_code" : 
          {
             "compound_code" : "GW67+3J Londres",
             "global_code" : "9C3XGW67+3J"
          },
          "rating" : 4.5,
          "reference" : "ChIJDz0Q3FMDdkgRw0x0c68WGtk",
          "types" : 
          [
             "tourist_attraction",
             "point_of_interest",
             "establishment"
          ],
          "user_ratings_total" : 12432
       },
       {
          "business_status" : "OPERATIONAL",
          "formatted_address" : "King Charles St, London SW1A 2AQ, Royaume-Uni",
          "geometry" : 
          {
             "location" : 
             {
                "lat" : 51.5021585,
                "lng" : -0.1293572
             },
             "viewport" : 
             {
                "northeast" : 
                {
                   "lat" : 51.50358747989272,
                   "lng" : -0.1279010701072778
                },
                "southwest" : 
                {
                   "lat" : 51.50088782010727,
                   "lng" : -0.1306007298927222
                }
             }
          },
          "icon" : "https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/museum-71.png",
          "icon_background_color" : "#13B5C7",
          "icon_mask_base_uri" : "https://maps.gstatic.com/mapfiles/place_api/icons/v2/museum_pinlet",
          "name" : "Churchill War Rooms",
          "opening_hours" : 
          {
             "open_now" : true
          },
          "photos" : 
          [
             {
                "height" : 2448,
                "html_attributions" : 
                [
                   "\u003ca href=\"https://maps.google.com/maps/contrib/112867923364192590700\"\u003eIvan Kozyatnyk\u003c/a\u003e"
                ],
                "photo_reference" : "AWYs27wgG0y1e5AW75iIOa08uRWaserMY5eRuMrvFbCdL7LNkWUwjNyJbS__TC3SO87BYx0Hhd4EiRh2RwzDe1kUnJNpNhWWxDewgPAynhJHS3Ji3ZHEFyIrdjvq6WrxMesZQfo9VH77xu-KT9HfcFHoMZf3jc-Y7V3d6iy7TH14p0UI0sUD",
                "width" : 3264
             }
          ],
          "place_id" : "ChIJq4lX1doEdkgR5JXPstgQjc0",
          "plus_code" : 
          {
             "compound_code" : "GV2C+V7 Londres",
             "global_code" : "9C3XGV2C+V7"
          },
          "rating" : 4.6,
          "reference" : "ChIJq4lX1doEdkgR5JXPstgQjc0",
          "types" : 
          [
             "tourist_attraction",
             "museum",
             "point_of_interest",
             "establishment"
          ],
          "user_ratings_total" : 13727
       },
       {
          "business_status" : "OPERATIONAL",
          "formatted_address" : "Abingdon St, London SW1P 3JY, Royaume-Uni",
          "geometry" : 
          {
             "location" : 
             {
                "lat" : 51.49845759999999,
                "lng" : -0.1264205
             },
             "viewport" : 
             {
                "northeast" : 
                {
                   "lat" : 51.49994052989272,
                   "lng" : -0.1247881201072778
                },
                "southwest" : 
                {
                   "lat" : 51.49724087010728,
                   "lng" : -0.1274877798927222
                }
             }
          },
          "icon" : "https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/museum-71.png",
          "icon_background_color" : "#13B5C7",
          "icon_mask_base_uri" : "https://maps.gstatic.com/mapfiles/place_api/icons/v2/museum_pinlet",
          "name" : "Jewel Tower",
          "opening_hours" : 
          {
             "open_now" : false
          },
          "photos" : 
          [
             {
                "height" : 2268,
                "html_attributions" : 
                [
                   "\u003ca href=\"https://maps.google.com/maps/contrib/105664573845979369269\"\u003eIustinian D.\u003c/a\u003e"
                ],
                "photo_reference" : "AWYs27zbNO9weH_TPMPB2vu1T9hVSOfJjXH39aUQmYmpou4-YyFXHHCSkP6gcpJYtLNLRt6y69OKOWpAkt1P1gDmZYkv-IcYXqpiN1zRc10AtBWhYHlc4uZzCskVL6LSztGMhpxE_-8xUr3SEfykqeEm_430exjhqD9cOEjMxjh-66YAajxP",
                "width" : 4032
             }
          ],
          "place_id" : "ChIJF5YlesMEdkgRT4fo3zv-vBo",
          "plus_code" : 
          {
             "compound_code" : "FVXF+9C Londres",
             "global_code" : "9C3XFVXF+9C"
          },
          "rating" : 4,
          "reference" : "ChIJF5YlesMEdkgRT4fo3zv-vBo",
          "types" : 
          [
             "tourist_attraction",
             "museum",
             "point_of_interest",
             "establishment"
          ],
          "user_ratings_total" : 573
       },
       {
          "business_status" : "OPERATIONAL",
          "formatted_address" : "Riverside Building, County Hall, Westminster Bridge Rd, London SE1 7PB, Royaume-Uni",
          "geometry" : 
          {
             "location" : 
             {
                "lat" : 51.50251189999999,
                "lng" : -0.1187628
             },
             "viewport" : 
             {
                "northeast" : 
                {
                   "lat" : 51.50404607989272,
                   "lng" : -0.11651875
                },
                "southwest" : 
                {
                   "lat" : 51.50134642010728,
                   "lng" : -0.12067015
                }
             }
          },
          "icon" : "https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/generic_business-71.png",
          "icon_background_color" : "#7B9EB0",
          "icon_mask_base_uri" : "https://maps.gstatic.com/mapfiles/place_api/icons/v2/generic_pinlet",
          "name" : "London Dungeon",
          "opening_hours" : 
          {
             "open_now" : false
          },
          "photos" : 
          [
             {
                "height" : 2524,
                "html_attributions" : 
                [
                   "\u003ca href=\"https://maps.google.com/maps/contrib/114610628376887622359\"\u003eKirill Tolstikov\u003c/a\u003e"
                ],
                "photo_reference" : "AWYs27zA2LglW1eJ0eSncX3Fxn4PX5s6uvHWMw8B4wDr_INKjk8ozNCpE-o7k7okGW8xAt0dZi8lZIspYvEAXBEVm5ZyI3J1mjkEoIg3GZv_5dokSish7X7q7BBd8Hh0tjF4zRm7u8GB-Jq2hFjEsnB21fwjOLbC3GXILu6RotMEHq3klRAC",
                "width" : 3853
             }
          ],
          "place_id" : "ChIJk9LIC8cEdkgR2TkO-raMEDk",
          "plus_code" : 
          {
             "compound_code" : "GV3J+2F Londres",
             "global_code" : "9C3XGV3J+2F"
          },
          "rating" : 4.3,
          "reference" : "ChIJk9LIC8cEdkgR2TkO-raMEDk",
          "types" : 
          [
             "tourist_attraction",
             "point_of_interest",
             "establishment"
          ],
          "user_ratings_total" : 18489
       },
       {
          "business_status" : "OPERATIONAL",
          "formatted_address" : "149 Piccadilly, London W1J 7NT, Royaume-Uni",
          "geometry" : 
          {
             "location" : 
             {
                "lat" : 51.5034733,
                "lng" : -0.1516714
             },
             "viewport" : 
             {
                "northeast" : 
                {
                   "lat" : 51.50463777989272,
                   "lng" : -0.1503813701072778
                },
                "southwest" : 
                {
                   "lat" : 51.50193812010728,
                   "lng" : -0.1530810298927222
                }
             }
          },
          "icon" : "https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/museum-71.png",
          "icon_background_color" : "#13B5C7",
          "icon_mask_base_uri" : "https://maps.gstatic.com/mapfiles/place_api/icons/v2/museum_pinlet",
          "name" : "Apsley House",
          "opening_hours" : 
          {
             "open_now" : false
          },
          "photos" : 
          [
             {
                "height" : 800,
                "html_attributions" : 
                [
                   "\u003ca href=\"https://maps.google.com/maps/contrib/111896108643068546200\"\u003eApsley House\u003c/a\u003e"
                ],
                "photo_reference" : "AWYs27whmxh1ddvhLfYN7MYXU2kO36p1SqWWPHGkplm5HzQTNyR6uixIGYpKVk3J-mnIqKeR0WCjdPRPdt-VzzPVp2f5DLq6SeRLanVjDzIx9yK25MvpoKBtrnrjtYJARxv6FoYNUqslk2dWm62AUwkbX7BTmbJ62aHF-DGGInD4EgQaGOOE",
                "width" : 1200
             }
          ],
          "place_id" : "ChIJ2_19mdYEdkgRadLE5rfxLPU",
          "plus_code" : 
          {
             "compound_code" : "GR3X+98 Londres",
             "global_code" : "9C3XGR3X+98"
          },
          "rating" : 4.6,
          "reference" : "ChIJ2_19mdYEdkgRadLE5rfxLPU",
          "types" : 
          [
             "museum",
             "tourist_attraction",
             "point_of_interest",
             "establishment"
          ],
          "user_ratings_total" : 1104
       },
       {
          "business_status" : "OPERATIONAL",
          "formatted_address" : "London SW1A 1AA, Royaume-Uni",
          "geometry" : 
          {
             "location" : 
             {
                "lat" : 51.501364,
                "lng" : -0.14189
             },
             "viewport" : 
             {
                "northeast" : 
                {
                   "lat" : 51.50301952989273,
                   "lng" : -0.13913855
                },
                "southwest" : 
                {
                   "lat" : 51.50031987010728,
                   "lng" : -0.14280715
                }
             }
          },
          "icon" : "https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/generic_business-71.png",
          "icon_background_color" : "#7B9EB0",
          "icon_mask_base_uri" : "https://maps.gstatic.com/mapfiles/place_api/icons/v2/generic_pinlet",
          "name" : "Palais de Buckingham",
          "opening_hours" : {},
          "photos" : 
          [
             {
                "height" : 1005,
                "html_attributions" : 
                [
                   "\u003ca href=\"https://maps.google.com/maps/contrib/104564895587039920725\"\u003eA Google User\u003c/a\u003e"
                ],
                "photo_reference" : "AWYs27y-wNTKG-TpcRGk7iKTxSHgJED0vqHuKOuImY6bw3ECudCEnzTLmgODbbP176_sC3k9zCIMytozvFXbKpENpcRonUtI_yWJvBajjLIU7lOFRe4bmMHW1eJnHFt2Xu1LEbDu4pUHl1MGWWgLy5HweNAJ4gs9VPdV6Fwr5MNvRxA78ab4",
                "width" : 1500
             }
          ],
          "place_id" : "ChIJtV5bzSAFdkgRpwLZFPWrJgo",
          "plus_code" : 
          {
             "compound_code" : "GV25+G6 Londres",
             "global_code" : "9C3XGV25+G6"
          },
          "rating" : 4.5,
          "reference" : "ChIJtV5bzSAFdkgRpwLZFPWrJgo",
          "types" : 
          [
             "tourist_attraction",
             "point_of_interest",
             "establishment"
          ],
          "user_ratings_total" : 176793
       },
       {
          "business_status" : "OPERATIONAL",
          "formatted_address" : "St James's Palace, St. James's Palace, Marlborough Rd, London SW1A 1BQ, Royaume-Uni",
          "geometry" : 
          {
             "location" : 
             {
                "lat" : 51.5045855,
                "lng" : -0.1378269
             },
             "viewport" : 
             {
                "northeast" : 
                {
                   "lat" : 51.50614487989272,
                   "lng" : -0.1361267201072778
                },
                "southwest" : 
                {
                   "lat" : 51.50344522010727,
                   "lng" : -0.1388263798927222
                }
             }
          },
          "icon" : "https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/generic_business-71.png",
          "icon_background_color" : "#13B5C7",
          "icon_mask_base_uri" : "https://maps.gstatic.com/mapfiles/place_api/icons/v2/generic_pinlet",
          "name" : "Palais Saint James",
          "photos" : 
          [
             {
                "height" : 4032,
                "html_attributions" : 
                [
                   "\u003ca href=\"https://maps.google.com/maps/contrib/109629106644642438903\"\u003eMiguel Premoli\u003c/a\u003e"
                ],
                "photo_reference" : "AWYs27ywRmhiNcjT4dTEb4YiZ82aROu1XRNR9PCdzLq1zID8LIWEj9leH-HQRa9U0k1VQLsMyAvdwU52fEBojiCnKzyLfcqpAFiGEWQCjGCnNyf_XdRqYQYCxPQZ-4s_Y-T2Ip5Pg15DNjmIq1ZyxXvQLzo1_kwB6FJJGPlQrpvAhi8uDDvk",
                "width" : 3024
             }
          ],
          "place_id" : "ChIJP4cHLtgEdkgR-ymCYYyiAwM",
          "plus_code" : 
          {
             "compound_code" : "GV36+RV Londres",
             "global_code" : "9C3XGV36+RV"
          },
          "rating" : 4.5,
          "reference" : "ChIJP4cHLtgEdkgR-ymCYYyiAwM",
          "types" : 
          [
             "tourist_attraction",
             "point_of_interest",
             "establishment"
          ],
          "user_ratings_total" : 3275
       },
       {
          "business_status" : "OPERATIONAL",
          "formatted_address" : "Winchester Palace, Pickfords Wharf, London SE1 9DN, Royaume-Uni",
          "geometry" : 
          {
             "location" : 
             {
                "lat" : 51.506878,
                "lng" : -0.09094919999999999
             },
             "viewport" : 
             {
                "northeast" : 
                {
                   "lat" : 51.50826912989272,
                   "lng" : -0.08964197010727779
                },
                "southwest" : 
                {
                   "lat" : 51.50556947010728,
                   "lng" : -0.09234162989272222
                }
             }
          },
          "icon" : "https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/generic_business-71.png",
          "icon_background_color" : "#7B9EB0",
          "icon_mask_base_uri" : "https://maps.gstatic.com/mapfiles/place_api/icons/v2/generic_pinlet",
          "name" : "Winchester Palace",
          "opening_hours" : 
          {
             "open_now" : true
          },
          "photos" : 
          [
             {
                "height" : 4032,
                "html_attributions" : 
                [
                   "\u003ca href=\"https://maps.google.com/maps/contrib/113123536946086959501\"\u003eKelly Woods\u003c/a\u003e"
                ],
                "photo_reference" : "AWYs27y4UbYjd4F-birqPnu4IiY7IkRx3-eSET6_vwzAESQ9DLBC_lTVG5VKXBWCRFZ36KjflEwLqsUVi9GvSmwp0t8ebj1CuYEc3q1a0qrBuTSXyvJO_HR6Zo7rpOEBIyTLKln5_wwa6k6MEtOLprxx5E3O6fQs2poBTH0E8PLjCMzKyKE-",
                "width" : 3024
             }
          ],
          "place_id" : "ChIJO2Z5qlcDdkgRcj_TBi0OfSQ",
          "plus_code" : 
          {
             "compound_code" : "GW45+QJ Londres",
             "global_code" : "9C3XGW45+QJ"
          },
          "rating" : 4.4,
          "reference" : "ChIJO2Z5qlcDdkgRcj_TBi0OfSQ",
          "types" : 
          [
             "tourist_attraction",
             "point_of_interest",
             "establishment"
          ],
          "user_ratings_total" : 214
       },
       {
          "business_status" : "OPERATIONAL",
          "formatted_address" : "1 Clink St, London SE1 9DG, Royaume-Uni",
          "geometry" : 
          {
             "location" : 
             {
                "lat" : 51.5070209,
                "lng" : -0.0918904
             },
             "viewport" : 
             {
                "northeast" : 
                {
                   "lat" : 51.50841402989272,
                   "lng" : -0.09096562010727778
                },
                "southwest" : 
                {
                   "lat" : 51.50571437010728,
                   "lng" : -0.09366527989272222
                }
             }
          },
          "icon" : "https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/museum-71.png",
          "icon_background_color" : "#13B5C7",
          "icon_mask_base_uri" : "https://maps.gstatic.com/mapfiles/place_api/icons/v2/museum_pinlet",
          "name" : "The Clink Prison Museum",
          "opening_hours" : 
          {
             "open_now" : true
          },
          "photos" : 
          [
             {
                "height" : 4032,
                "html_attributions" : 
                [
                   "\u003ca href=\"https://maps.google.com/maps/contrib/114817678042908536535\"\u003eRobin Van de Kerkhof\u003c/a\u003e"
                ],
                "photo_reference" : "AWYs27z_WVh4PDtRvKKSIf9krbzfO1LPmTBshKjp4gT-uYx86NOTJbPpSRritYjtLupv4DPdh-YuTRfz8Ubg-Zmcrqad8lQPxjZCA7EyOVMmpUt5Zasn-YGltGBmIOWmahtMeakvclRF64xypZ98T45MYaR2EQSS5Ke0V_uTE63jHjOADTj6",
                "width" : 3024
             }
          ],
          "place_id" : "ChIJSdAbs1cDdkgRh097pZt1ds4",
          "plus_code" : 
          {
             "compound_code" : "GW45+R6 Londres",
             "global_code" : "9C3XGW45+R6"
          },
          "rating" : 4.1,
          "reference" : "ChIJSdAbs1cDdkgRh097pZt1ds4",
          "types" : 
          [
             "tourist_attraction",
             "museum",
             "point_of_interest",
             "establishment"
          ],
          "user_ratings_total" : 2093
       },
       {
          "business_status" : "OPERATIONAL",
          "formatted_address" : "Lambeth Rd, London SE1 6HZ, Royaume-Uni",
          "geometry" : 
          {
             "location" : 
             {
                "lat" : 51.4958366,
                "lng" : -0.1086584
             },
             "viewport" : 
             {
                "northeast" : 
                {
                   "lat" : 51.49770050000001,
                   "lng" : -0.10358045
                },
                "southwest" : 
                {
                   "lat" : 51.49479849999999,
                   "lng" : -0.11339785
                }
             }
          },
          "icon" : "https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/museum-71.png",
          "icon_background_color" : "#13B5C7",
          "icon_mask_base_uri" : "https://maps.gstatic.com/mapfiles/place_api/icons/v2/museum_pinlet",
          "name" : "Imperial War Museum",
          "opening_hours" : 
          {
             "open_now" : true
          },
          "photos" : 
          [
             {
                "height" : 9000,
                "html_attributions" : 
                [
                   "\u003ca href=\"https://maps.google.com/maps/contrib/100858865425125418112\"\u003eThomas Capper\u003c/a\u003e"
                ],
                "photo_reference" : "AWYs27xi6VAOw8kS1GKDc4lfRTqAse15vKGXImO8hibtS7BxeYpGA6FZU5y9XEjCnkXKfNZzMbHXmLKMn6oHbDZImcoQjEiT9PlzUWEwTu-hm6Gbc_WqzyksMxGZYqsY3wysL6p3GPBCKq1-KplPkBoGx9kMao7aPUAj0BwQW4aZZXFadZg_",
                "width" : 12000
             }
          ],
          "place_id" : "ChIJ0-48HywBdkgRh7MH0Igd1f4",
          "plus_code" : 
          {
             "compound_code" : "FVWR+8G Londres",
             "global_code" : "9C3XFVWR+8G"
          },
          "rating" : 4.7,
          "reference" : "ChIJ0-48HywBdkgRh7MH0Igd1f4",
          "types" : 
          [
             "museum",
             "tourist_attraction",
             "travel_agency",
             "point_of_interest",
             "establishment"
          ],
          "user_ratings_total" : 28787
       },
       {
          "business_status" : "OPERATIONAL",
          "formatted_address" : "London SE1 7JU, Royaume-Uni",
          "geometry" : 
          {
             "location" : 
             {
                "lat" : 51.4957797,
                "lng" : -0.1198461
             },
             "viewport" : 
             {
                "northeast" : 
                {
                   "lat" : 51.49673807989272,
                   "lng" : -0.11689515
                },
                "southwest" : 
                {
                   "lat" : 51.49403842010728,
                   "lng" : -0.12221335
                }
             }
          },
          "icon" : "https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/generic_business-71.png",
          "icon_background_color" : "#13B5C7",
          "icon_mask_base_uri" : "https://maps.gstatic.com/mapfiles/place_api/icons/v2/generic_pinlet",
          "name" : "Lambeth Palace",
          "photos" : 
          [
             {
                "height" : 3472,
                "html_attributions" : 
                [
                   "\u003ca href=\"https://maps.google.com/maps/contrib/107905199197469440373\"\u003eA Google User\u003c/a\u003e"
                ],
                "photo_reference" : "AWYs27zi7nthE71jbugMMVm8otFHHRXq_TcvoKoupQQOkFy_qL1Gtc-FcpIQ6qyNUmwwiquchSAmFhyG8EdfxRUuxsQnoi5cmgndO0qcSe_pSyVLjdMAB_Rctcf2NaQ0aCA77kwrDoNp2bqu1Dl1uBc67B9LOqAI76VuHdYrfVJf_Id6rz8_",
                "width" : 3472
             }
          ],
          "place_id" : "ChIJoeMzysEEdkgRF8t3uM9I5gw",
          "plus_code" : 
          {
             "compound_code" : "FVWJ+83 Londres",
             "global_code" : "9C3XFVWJ+83"
          },
          "rating" : 4.5,
          "reference" : "ChIJoeMzysEEdkgRF8t3uM9I5gw",
          "types" : 
          [
             "tourist_attraction",
             "point_of_interest",
             "establishment"
          ],
          "user_ratings_total" : 740
       },
       {
          "business_status" : "OPERATIONAL",
          "formatted_address" : "London WC2E 7BB, Royaume-Uni",
          "geometry" : 
          {
             "location" : 
             {
                "lat" : 51.5119031,
                "lng" : -0.1215852
             },
             "viewport" : 
             {
                "northeast" : 
                {
                   "lat" : 51.51309067989272,
                   "lng" : -0.1202296201072778
                },
                "southwest" : 
                {
                   "lat" : 51.51039102010728,
                   "lng" : -0.1229292798927222
                }
             }
          },
          "icon" : "https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/museum-71.png",
          "icon_background_color" : "#13B5C7",
          "icon_mask_base_uri" : "https://maps.gstatic.com/mapfiles/place_api/icons/v2/museum_pinlet",
          "name" : "Musée du transport de Londres",
          "opening_hours" : 
          {
             "open_now" : true
          },
          "photos" : 
          [
             {
                "height" : 6936,
                "html_attributions" : 
                [
                   "\u003ca href=\"https://maps.google.com/maps/contrib/115067780571793013102\"\u003eA Google User\u003c/a\u003e"
                ],
                "photo_reference" : "AWYs27zjRR9Bsr0Z4w-TtTC9XwIx5yZZq54qUxKp4me3-u6Gq6f37XSeJxZrDe2zp3_ZBmQb5MzGlcZ10Sixas1uJj1x2W-onaBUdcXtHbCvBMsZubwC-hXUEIpwlP2NJgXJwkNrf3Ki5ZSQcKDEQBZOA2OdQK_-qQ_lUN7_DdTGI7BMzZJG",
                "width" : 9248
             }
          ],
          "place_id" : "ChIJ4bF21K8FdkgRDXc6FiSVAzE",
          "plus_code" : 
          {
             "compound_code" : "GV6H+Q9 Londres",
             "global_code" : "9C3XGV6H+Q9"
          },
          "rating" : 4.4,
          "reference" : "ChIJ4bF21K8FdkgRDXc6FiSVAzE",
          "types" : 
          [
             "museum",
             "tourist_attraction",
             "cafe",
             "food",
             "point_of_interest",
             "store",
             "establishment"
          ],
          "user_ratings_total" : 11368
       },
       {
          "business_status" : "OPERATIONAL",
          "formatted_address" : "A400 Trafalgar Square, London, Royaume-Uni",
          "geometry" : 
          {
             "location" : 
             {
                "lat" : 51.5077638,
                "lng" : -0.1279249
             },
             "viewport" : 
             {
                "northeast" : 
                {
                   "lat" : 51.50911837989272,
                   "lng" : -0.1263962201072778
                },
                "southwest" : 
                {
                   "lat" : 51.50641872010728,
                   "lng" : -0.1290958798927222
                }
             }
          },
          "icon" : "https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/generic_business-71.png",
          "icon_background_color" : "#13B5C7",
          "icon_mask_base_uri" : "https://maps.gstatic.com/mapfiles/place_api/icons/v2/generic_pinlet",
          "name" : "Colonne Nelson",
          "opening_hours" : 
          {
             "open_now" : true
          },
          "photos" : 
          [
             {
                "height" : 9000,
                "html_attributions" : 
                [
                   "\u003ca href=\"https://maps.google.com/maps/contrib/106760349512265909788\"\u003eHenry Purser\u003c/a\u003e"
                ],
                "photo_reference" : "AWYs27xxSH-dNly_kajUKC-43vjCKNBiBsQ_HUW4LC3XQCKRVZDZQJSwwHiaCNtUr9T_4B9s2jb-boQuOOSntXHvL8Z9GF9Q58huwQT_gdr1Cp4QbLH-0jObTfBDP-xj0OHDAKqIzxujxlyIPmZDGYUUad7Y9XEsprusWAJvoEkrmquDaL38",
                "width" : 12000
             }
          ],
          "place_id" : "ChIJaxhvijEFdkgRPtwPZgzI4w8",
          "plus_code" : 
          {
             "compound_code" : "GV5C+4R Londres",
             "global_code" : "9C3XGV5C+4R"
          },
          "rating" : 4.6,
          "reference" : "ChIJaxhvijEFdkgRPtwPZgzI4w8",
          "types" : 
          [
             "point_of_interest",
             "establishment"
          ],
          "user_ratings_total" : 449
       },
       {
          "business_status" : "OPERATIONAL",
          "formatted_address" : "Trafalgar Square, London WC2N 5DN, Royaume-Uni",
          "geometry" : 
          {
             "location" : 
             {
                "lat" : 51.50892899999999,
                "lng" : -0.128299
             },
             "viewport" : 
             {
                "northeast" : 
                {
                   "lat" : 51.51035402989272,
                   "lng" : -0.1274044
                },
                "southwest" : 
                {
                   "lat" : 51.50765437010728,
                   "lng" : -0.1307096
                }
             }
          },
          "icon" : "https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/museum-71.png",
          "icon_background_color" : "#13B5C7",
          "icon_mask_base_uri" : "https://maps.gstatic.com/mapfiles/place_api/icons/v2/museum_pinlet",
          "name" : "National Gallery",
          "opening_hours" : 
          {
             "open_now" : true
          },
          "photos" : 
          [
             {
                "height" : 3456,
                "html_attributions" : 
                [
                   "\u003ca href=\"https://maps.google.com/maps/contrib/104517689172849189916\"\u003eDaniela Bonamigo\u003c/a\u003e"
                ],
                "photo_reference" : "AWYs27yChgsT5c56brCIUvkbp2i-bg5TOFR37ofDje9U3U8jdTl4yMQ1U6fin-aQQiRzH5C4eJ_fSzKe2yS-ohD4v-9J7KHXvfGMexbs-X1Ow9icsfeQSE3RGjxXr_puWV5kTYWpKXYH5Fun_LJTkI3RFLleSGou0--1xYfAaErZDylCO0wI",
                "width" : 5184
             }
          ],
          "place_id" : "ChIJeclqF84EdkgRtKAjTmWFr0I",
          "plus_code" : 
          {
             "compound_code" : "GV5C+HM Londres",
             "global_code" : "9C3XGV5C+HM"
          },
          "rating" : 4.7,
          "reference" : "ChIJeclqF84EdkgRtKAjTmWFr0I",
          "types" : 
          [
             "art_gallery",
             "tourist_attraction",
             "museum",
             "point_of_interest",
             "establishment"
          ],
          "user_ratings_total" : 52467
       },
       {
          "business_status" : "OPERATIONAL",
          "formatted_address" : "Dean's Yard, London SW1P 3PA, Royaume-Uni",
          "geometry" : 
          {
             "location" : 
             {
                "lat" : 51.4993695,
                "lng" : -0.1272993
             },
             "viewport" : 
             {
                "northeast" : 
                {
                   "lat" : 51.50091017989273,
                   "lng" : -0.12633705
                },
                "southwest" : 
                {
                   "lat" : 51.49821052010729,
                   "lng" : -0.12997045
                }
             }
          },
          "icon" : "https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/worship_general-71.png",
          "icon_background_color" : "#7B9EB0",
          "icon_mask_base_uri" : "https://maps.gstatic.com/mapfiles/place_api/icons/v2/worship_christian_pinlet",
          "name" : "Abbaye de Westminster",
          "photos" : 
          [
             {
                "height" : 3297,
                "html_attributions" : 
                [
                   "\u003ca href=\"https://maps.google.com/maps/contrib/117615558177504061878\"\u003eMy World\u003c/a\u003e"
                ],
                "photo_reference" : "AWYs27wTXCOUeJKyOQUmVkYpV5zB7iJ6npjk5eOBalpFDAX30_9Mg2LXE-sGeXsTgK-cOLAPSnIO4FybxKmW7GCnD_asIyEJlzOVyNUvPB6gxzaFlNA1qv_oxUtUNeSTX_HpFj8ovO1o1jzpWiDnSsbRnel-uO9lI46g2OWPlBMI6qJGgHi8",
                "width" : 4853
             }
          ],
          "place_id" : "ChIJLzVDusQEdkgRelObBaL_jto",
          "plus_code" : 
          {
             "compound_code" : "FVXF+P3 Londres",
             "global_code" : "9C3XFVXF+P3"
          },
          "rating" : 4.6,
          "reference" : "ChIJLzVDusQEdkgRelObBaL_jto",
          "types" : 
          [
             "tourist_attraction",
             "church",
             "place_of_worship",
             "point_of_interest",
             "establishment"
          ],
          "user_ratings_total" : 44264
       }
    ],
    "status" : "OK"
 }
 
 export default places;